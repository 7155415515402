@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@500;600&family=Poppins:wght@600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/*TODO:  ========= default ========= */
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-family: 'Inter', sans-serif;
  background-color: #f8f8f8;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.font-400 {
  font-weight: 400;
}
.font-600 {
  font-weight: 600;
}
.container-x {
  @apply md:px-6 px-4 w-full;
  max-width: 1216px !important;
}
.animate-right-dir {
  animation: rightDir 1s infinite;
}
.logo-text {
  display: flex;
  flex-direction: column;
}

.logo-text h5 {
  font-family: 'Poppins', sans-serif;
  line-height: 100%;
}

.logo-text p {
  font-family: 'Montserrat', sans-serif;
  line-height: 100%;
  color: #797e8b;
}

@keyframes rightDir {
  0%,
  100% {
    transform: translatex(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.yellow-btn {
  @apply w-full text-[13px] text-qblacktext font-semibold bg-qyellow flex justify-center items-center h-full rounded-lg;
  line-height: 0;
}
.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.search-input {
  @apply w-full h-full focus:outline-none focus:ring-0 pl-5 text-xs font-medium placeholder:text-qgraytwo;
}
.black-btn {
  @apply bg-qblack text-white rounded-lg px-4;
}
.yellow-btn {
  @apply bg-qyellow text-white rounded-lg px-4;
}
.red-btn {
  @apply bg-red-800 text-white rounded-lg px-4;
}
.breadcrumb-wrapper span:last-child .sperator {
  display: none;
}

.category-dropdown {
  transition: all 0.5s ease-in-out;
}
.category .category-dropdown .categories-list .category-item {
  border-top: 1px solid #f8f8f8;
}

.nav-widget-wrapper .nav ul.nav-wrapper > li {
  padding: 20px 0;
}

@media (max-width: 768px) {
  .category-products .products-section .item:nth-child(3),
  .best-sallers-section .section-content .item:nth-child(3),
  .best-sallers-section .section-content .item:nth-child(4),
  .single-product-wrapper .related-product .item:nth-child(3) {
    display: none;
  }
}

@media (max-width: 1024px) {
  .best-sallers-section .section-content .item:nth-child(6),
  .single-product-wrapper .related-product .item:nth-child(4) {
    display: none;
  }
}

button:disabled {
  opacity: 0.7;
}

button,
input,
textarea,
.input-wrapper,
.react-datetime-picker__wrapper {
  @apply rounded-lg;
}

.products-sorting,
.filter-widget {
  @apply rounded-2xl;
}

.toast-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  right: 24px;
  top: 24px;
  width: 374px;
  z-index: 9999;
}

@media screen and (max-width: 576px) {
  .toast-container {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    width: calc(100% - 40px);
  }
}

@keyframes show_slide {
  0% {
    margin: -20px 0 0 0;
  }

  100% {
    margin: 0;
  }
}

.alert {
  transition: 0.3s ease;
  animation: show_slide 0.3s ease forwards;
}

@media screen and (max-width: 768px) {
  .company-info {
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
  }
  .company-info > img {
    width: 100%;
    margin: 0 auto;
    max-width: 300px;
  }
}

.count-gradient {
  background: linear-gradient(180deg, #061a36 16.46%, rgba(6, 26, 54, 0) 134.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  line-height: initial;
}

.main-logo {
  padding: 10px 0;
  font-size: 12px;
}

@media screen and (max-width: 1024px) {
  .main-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media screen and (min-width: 580px) and (max-width: 740px) {
  .gift-cards-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (max-width: 530px) {
  .gift-cards-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media screen and (max-width: 490px) {
  .top-products {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .product-row-card-style-one {
    overflow: visible;
    height: auto;
  }
  .product-row-card-style-one > .product-row-content {
    flex-direction: column;
    padding: 0 0 18px;
    gap: 0;
  }

  .product-row-content .product-info {
    width: 100%;
    margin: 0;
    padding: 12px 30px 0;
  }

  .top-products .product-image {
    width: 100%;
  }

  .product-row-content .product-info .price {
    margin: 0;
  }
  .product-row-content .product-info .title {
    height: 48px;
    font-size: 15px;
  }
}

@media screen and (max-width: 490px) {
  .top-products {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .top-products .product-row-card-style-one {
    overflow: hidden;
    height: auto;
  }
  .product-row-card-style-one .product-row-content {
    flex-direction: column;
    padding: 0 0 18px;
  }

  .product-row-content .product-info {
    width: 100%;
    margin: 0;
    padding: 12px 30px 0;
  }

  .top-products .product-image {
    width: 100%;
  }

  .product-row-content .product-image img {
    object-fit: cover;
  }

  .product-row-content .product-info .price {
    margin: 0;
  }
  .product-row-content .product-info .title {
    height: 48px;
    font-size: 15px;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.common-list {
  list-style: disc;
  list-style-type: disc;
  padding: inherit;
}

.common-list li {
  list-style: disc;
  list-style-type: disc;
}

.common-list li::marker {
  content: initial;
}

.hero-company-title {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 280px;
  max-width: 85%;
  padding: 12px 18px;
  color: #fff;
  background: #061a36;
}
.hero-company-title::before,
.hero-company-title::after {
  position: absolute;
  width: 100vw;
  height: 2px;
  background: #ffc24a;
  content: '';
}
.hero-company-title::before {
  top: -2px;
  right: 10px;
}

.hero-company-title::after {
  bottom: -2px;
  left: 10px;
}
@media screen and (max-width: 767px) {
  .hero-company-title {
    padding: 9px 18px;
    min-height: 66px;
    width: 100%;
    max-width: 87%;
    min-width: 230px;
  }
}

.ad-banner-card > div:first-child::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.5;
  transform: matrix(1, 0, 0, -1, 0, 0);
  border-radius: 0 0 20px 20px;
}
.ad-banner-card > div:first-child > div {
  position: relative;
  z-index: 2;
}
.ad-banner-card > div:last-child {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.8;
}

.primary-select-wrapper {
  position: relative;
}
.primary-select-wrapper > .arrow {
  position: absolute;
  right: 0;
  top: 50%;
}

.primary-select {
  background: none;
  padding-right: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.slick-dots {
  bottom: 0 !important;
  z-index: 5 !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
  color: #999999 !important;
  opacity: 0.6 !important;
}

.slick-dots li.slick-active button:before {
  color: #000 !important;
  opacity: 0.8 !important;
}

.slick-slide > div:nth-child(1):nth-last-child(2) {
  margin-bottom: 10px;
}
.companies-slider .slick-track {
  display: flex;
  gap: 20px;
  margin: 0;
}

.close-button {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  background: #fff;
  border: 1px solid #810000;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button span {
  width: 2px;
  height: 12px;
  background: #810000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  border-radius: 2px;
}

.close-button :nth-child(1) {
  transform: translate(-50%, -50%) rotate(135deg);
}

.close-button :nth-child(2) {
  transform: translate(-50%, -50%) rotate(-135deg);
}
.close-button :nth-child(2) :hover {
  border: 1px solid #810000;
  background: #810000;
  span {
    background: #fff;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.loading-screen img {
  animation: pulse 2s infinite;
}

.slider-item {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative !important;
}

.products-page-wrapper .filter-widget .filter-subject-item .filter-items ul li.item:last-child {
  margin-bottom: 0;
}
/* range slider */
.input-range__track {
  background: #d4d4d4;
}
.input-range__track.input-range__track--active {
  background: #fddc62 !important;
}
.input-range__label.input-range__label--max,
.input-range__label.input-range__label--min,
.input-range__label.input-range__label--value {
  display: none;
}
.input-range__slider {
  appearance: none !important;
  background: #fddc62 !important;
  border: 1px solid #fddc62 !important;
}

.input-range__slider {
  background: #ffffff;
  border: 1px solid #ffffff;
  width: 14px;
  height: 14px;
  box-shadow: 0px 3px 10px 0px rgba(204, 204, 204, 1);
}

.regular.slider.companies-slider {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.slider-controlls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  bottom: 24px;
  right: 24px;
  height: 40px;
  border-radius: 50px;
  background: rgba(51, 51, 51, 0.9);
  color: #fff;
  min-width: 88px;
  padding: 0 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 450px) {
  .slider-controlls {
    display: none;
  }
}

.image-gallery-slide .image-gallery-image {
  max-width: 100%;
  object-fit: initial !important;
  max-height: 300px !important;
  border-radius: 0.75rem;
  width: auto !important;
  height: auto !important;
  margin: auto;
}
.image-gallery-slide.image-gallery-center {
  display: flex !important;
}
@media screen and (max-width: 600px) {
  .image-gallery-slide .image-gallery-image {
    max-height: 168px !important;
  }
}

@media screen and (max-width: 340px) {
  .fc .fc-toolbar-title {
    font-size: 12px;
    font-weight: 600;
  }
  .fc .fc-button {
    font-size: 12px;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 4px;
  }
}

@media screen and (min-width: 341px) and (max-width: 479px) {
  .fc .fc-toolbar-title {
    font-size: 15px;
    font-weight: 600;
  }
  .fc .fc-button {
    font-size: 14px;
  }
}

@media screen and (min-width: 480px) and (max-width: 740px) {
  .fc .fc-toolbar-title {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 740px) and (max-width: 806px) {
  .fc .fc-toolbar-title {
    font-size: 1.4rem;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* .excell-table td {
  padding: 4px;
  border: 1px solid #cecece;
} */
