.wheel-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wheel-wrapper #wheel {
  overflow: hidden;
  width: 450px;
  height: 450px;
  border-radius: 50%;
}

.wheel-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.wheel-wrapper #wheel canvas {
  width: 750px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .wheel-wrapper #wheel {
    width: 300px;
    height: 300px;
  }

  .wheel-wrapper #wheel canvas {
    width: 500px;
  }
}
@media screen and (max-width: 400px) {
  .wheel-wrapper #wheel {
    width: 240px;
    height: 240px;
  }

  .wheel-wrapper #wheel canvas {
    width: 400px;
  }
}
